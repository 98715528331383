<template>
    <Teleport to="body">
        <ContactEmail
            :id="'employee-email' + id"
            :items="teaser?.contact?.emails"
        />
        <ContactPhone
            :id="'employee-phone' + id"
            :items="teaser?.contact?.phones"
        />
    </Teleport>

    <div class="d-flex flex-column teaser">
        <NuxtLink
            v-if="teaser?.image"
            :to="teaser.button?.to"
            class="teaser__image"
        >
            <NuxtImg
                :src="teaser.image.src"
                :alt="teaser.image.alt"
                loading="lazy"
                format="webp"
                sizes="sm:340px md:450px lg:500px"
                densities="1x 2x"
                :placeholder="[300, 200, 60, 2]"
            />
        </NuxtLink>
        <div class="equal-base equal-base--tighter-bold-uppercase">
            {{ teaser.name }}
        </div>
        <div
            v-if="teaser.function"
            class="equal-base equal-base--tighter teaser__function"
        >
            {{ teaser.function }}
        </div>
        <ul class="d-flex flex-row teaser__contact">
            <li v-if="teaser?.contact?.emails">
                <FormButton
                    class="l-button--secondary l-button--icon-only"
                    :size40="true"
                    data-bs-toggle="modal"
                    :data-bs-target="'#employee-email' + id"
                >
                    <IconsMail />
                </FormButton>
            </li>
            <li v-if="teaser?.contact?.phones">
                <FormButton
                    class="l-button--secondary l-button--icon-only"
                    :size40="true"
                    data-bs-toggle="modal"
                    :data-bs-target="'#employee-phone' + id"
                >
                    <IconsPhone />
                </FormButton>
            </li>
        </ul>
        <FormButton
            :to="teaser.button?.to"
            class="flex-shrink-0 l-button--outlined teaser__button"
            :label="t('team.teaser.label')"
            component="NuxtLink"
        />
    </div>
</template>

<script setup>
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const props = defineProps({
    teaser: {
        type: Object,
        required: true,
    },
    id: {
        type: Number,
        required: true,
    },
});
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';
@import '@/node_modules/bootstrap/scss/mixins';

.teaser {
    width: 100%;
    position: relative;
    margin-bottom: 30px;

    &__image {
        position: relative;
        display: flex;
        overflow: hidden;
        width: 100%;
        height: clamp(400px, 15.28vw + 351.11px, 620px);
        margin-bottom: 30px;

        img {
            position: relative;
            display: flex;
            object-fit: cover;
            min-width: 100%;
            min-height: 100%;
            transition: 0.5s ease;
        }

        @include media-breakpoint-down(560) {
            margin: 0 $padding-container-negative 30px
                $padding-container-negative;
            width: auto;
        }
    }

    &__function {
        margin-top: 10px;
    }

    &__contact {
        list-style: none;
        padding: 0;
        margin: 20px 0 0 0;

        li {
            &:not(:last-child) {
                margin-right: 15px;
            }
        }
    }

    &__button {
        margin-top: 25px;
    }

    &:hover,
    &:focus,
    &:active {
        transition: 0.5s ease;

        .teaser__image {
            img {
                transform: scale(1.05);
                transition: 0.5s ease;
            }
        }
    }
}
</style>
