<template>
    <div class="row spacer-navigation spacer-footer">
        <div class="col-12">
            <h1 class="scale-6 team__heading" :id="slugifyString(title)">
                {{ title }}
            </h1>
            <p class="scale-1 scale-1--lead spacer-30 team__info">
                {{ info }}
            </p>
            <div class="team__grid">
                <TeamTeaserEmployee
                    v-for="(item, idx) in employees"
                    :teaser="item"
                    :id="idx"
                />
            </div>
        </div>
    </div>
</template>

<script setup>
import { computed } from 'vue';
import { slugifyString } from '~/utils/slugify';

const props = defineProps({
    pageObj: Object,
});

const page = toRef(props, 'pageObj');

const title = computed(() => page?.value?.title);
const info = computed(() => page?.value?.info);
const employees = computed(() => page?.value?.employees);
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';
@import '@/node_modules/bootstrap/scss/mixins';

.team {
    &__heading {
        margin-bottom: 15px;
    }

    &__info {
        width: 66%;

        @include media-breakpoint-down(640) {
            width: 100%;
        }

        margin-bottom: 60px;
    }

    &__grid {
        display: grid;
        grid-template-columns: repeat(3, minmax(0, 1fr));
        column-gap: clamp(50px, 0.69vw + 47.78px, 60px);
        row-gap: clamp(50px, 0.69vw + 47.78px, 60px);

        @include media-breakpoint-down(1200) {
            grid-template-columns: repeat(2, minmax(0, 1fr));
        }

        @include media-breakpoint-down(640) {
            grid-template-columns: repeat(1, minmax(0, 1fr));
        }
    }
}
</style>
